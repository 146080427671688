<template>
    <v-dialog v-model="dialogEditShow" max-width="600px">
      <v-card class="pa-4 pa-sm-8">
        <v-btn class="button-close ma-4" icon @click="dialogEditShow = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <h2 class="text-center">Промокод для активации</h2>
        <form @submit.prevent="save" class="mt-6">
            <div class="row">
              <div class="col-md-6">
                <v-select v-model="curCompany" :items="companies" @change="changeCompany"
                      label="Компания" :item-text="(item) => item.name" item-value="id"
                      outlined return-object hide-details
                />
              </div>
              <div class="col-md-6">
                <v-select  v-model="clientId" :items="filterManagers"
                      @change="changeManager" label="Распостранитель" hide-details
                      :item-text="(item) => item.first_name || item.last_name ? item.first_name + ' ' + item.last_name : 'имя не задано'"
                      item-value="id" outlined
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                    <v-select  v-model="curCode" :items="codes"
                      label="Промокод"
                      :item-text="(item) => item.title"
                      item-value="id"
                      outlined
                      :_disabled="!$store.getters['auth/isAllowed']('change_partner_of_corpclient')"
                    />
              </div>
            </div>

          <div class="row mt-3">
            <div class="col-md-6 mx-auto">
              <v-btn type="submit" class="w-100" color="primary" :loading="loading" :disabled="!curCode">
                Добавить
              </v-btn>
            </div>
          </div>
        </form>
      </v-card>
    </v-dialog>
</template>

<script>
/*eslint-disable*/
import { mapActions, mapGetters } from 'vuex';
import api from '@/common/api';

export default {
  model: {
      prop: 'value',
      event: 'input'
  },
  props: {
    value: Boolean,
    client: Object,
    partnerId: Number
  },
  data: () => ({
    codes: [],
    curCode: null,
    loading: false,
    curCompany: null,
    clientId: 0,
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
          await this.load();
      }
    },
    clientId(val) {
      this.loadCodes();
    }
  },
  computed: {
    ...mapGetters({
      clients: 'users/clients/clients',
      loadingClients: 'users/clients/loading',
    }),
    dialogEditShow: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', false);
      }
    },
    filterManagers() {
      const arr = this.clients.slice();
      arr.sort(function (item1, item2) {
        const a = item1.first_name || item1.last_name ? item1.first_name + ' ' + item1.last_name : 'имя не задано';
        const b = item2.first_name || item2.last_name ? item2.first_name + ' ' + item2.last_name : 'имя не задано';
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      return arr;
    },
    companies() {
      return this.clients
          .map(item => item.company)
          .filter(item => item)
          .sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
    }

  },
  methods: {
    ...mapActions({
      loadClients: 'users/clients/loadClients',
    }),
    async load() {
      if (this.clients?.length < 1 && !this.loadingClients.load) {
        await this.loadClients();
      }
      const client = this.clients[0]
      this.curCompany = client ? client.company  : "-"
      this.clientId = client.id
      // this.loadCodes();
    },

    async loadCodes() {
      try {
        this.curCode = 0;
        this.codes = await api.get(`/users/corp_clients/${this.clientId}/promocodes/`);
        this.curCode = this.codes.find(el => el.is_default)
        if (!this.curCode && this.codes.length)
          this.curCode = this.codes[0];
        if (this.curCode)
          this.curCode = this.curCode.id
      } catch (err) {
        this.$toast.error("Ошибка при загрузке промокодов", '', { position: 'topRight' });
      }
    },

    async save() {
      try {
        this.loading = true;
        console.log({ promocode: this.curCode });
        const response = await api.post(`/users/corp_clients/${this.client.id}/promocodes-used/`, {
          promocode: this.curCode,
          distributor: this.clientId
        });
        console.log(response);
        if (response) {
          this.dialogEditShow = false;
          this.$emit('ok');
        }
      } catch (err) {
        let msg = "Ошибка при добавлении промокода"
        if (err.data?.error)
          msg += ': ' + err.data?.error;
        this.$toast.error(msg, '', { position: 'topRight' });
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    changeCompany() {
      const manager = this.filterManagers.find(el => el.company === this.curCompany)
      if (manager)
        this.clientId = manager.id;
    },
    changeManager()  {
      if (this.clientId) {
        const manager = this.filterManagers.find(el => el.id === this.clientId)
        this.curCompany = manager && manager.company ? manager.company : "-"
      }
    },


  },
};

</script>
