<template>
  <div class="page">
    <template v-if="user.user_type === CORPCLIENT">
      <ClientPromocodes :userId="user.id" userType='CorpClient'/>
    </template>

    <template v-else-if="user.user_type === PARTNER">
      <PartnerPromocodes :userId="user.id" userType='Partner'/>
    </template>

    <template v-else-if="user.user_type === ADMIN">
      <PartnerPromocodes :userId="user.id" :userType="ADMIN"/>
    </template>
    <template v-else>
      Нет прав на просмотр этой страницы
    </template>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CANDIDATE, CORPCLIENT, PARTNER, MANAGER, ADMIN
} from '@/common/constants/userTypes';
import ClientPromocodes from '@/components/clients/profile/ClientPromocodes';
import PartnerPromocodes from '@/components/partners/profile/PartnerPromocodes';

export default {
  name: 'Profile',
  components: {
    ClientPromocodes, PartnerPromocodes
  },
  provide: {
    isProfile: true,
  },
  data: () => ({
    CANDIDATE, CORPCLIENT, PARTNER, MANAGER, ADMIN
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
    }),
  },
  created() {
  },
  beforeDestroy() {
  },
};
</script>
